<template>
  <div id="app">
    <HomeHeader />
    <HomeNav />
    <router-view />
    <HomeFooter />
  </div>
</template>
<script>
import HomeHeader from "./components/HomeHeader";
import HomeNav from "./components/HomeNav";
import HomeFooter from "./components/bottomView";
export default {
  name: "App",
  components: {
    HomeHeader,
    HomeNav,
    HomeFooter,
  },
};
</script>

<style lang="less">
@import url("https://at.alicdn.com/t/c/font_4650831_qb2uq81rba.css");
* {
  margin: 0;
  padding: 0;
}
#app {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 350px);
  background-color: #f0f0f0;
  padding-top: 150px;
  padding-bottom: 200px;
}
a {
  text-decoration: none;
  color: inherit;
}

/* 为所有具有滚动条的元素自定义滚动条样式 */
html {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  ::-webkit-scrollbar-track {
    background-color: #eee;
  }
  // scrollbar-width: thin; /* 设置滚动条宽度为较窄的宽度 */
  // scrollbar-color: #888 #f1f1f1; /* 设置滚动条滑块颜色和轨道颜色 */
}
li {
  list-style: none;
}
</style>
