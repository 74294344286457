//axios二次封装
import axios from "axios"
import { Message } from "element-ui"
const service = axios.create({
    baseURL: "https://ojs.acad-pub.com/index.php/",
    timeout: 8000,//请求超过这个时间失效
})

service.interceptors.response.use(function (response) {
    if (response.status == 200) {
        const res = response.data
        if (res.code == 200) {
            return res
        } else {
            Message({
                type: "error",
                message: res.message || "请求错误"
            });
            return Promise.reject(new Error(res.message || "请求错误"))
        }
    }
}), function (error) {
    Message({
        type: "error",
        message: error.message || "请求错误"
    });
    return Promise.reject(new Error("发送请求失败"))
}
export default service 